import React from "react";
import sanitizeHtml from 'sanitize-html';
import Layout from "../../components/layout";
import ContentListItems from "../content-list-items";
import MageButton from '../mageButton';
import scrollTo from 'gatsby-plugin-smoothscroll';
import {Helmet} from "react-helmet";
import {globalHistory as history} from '@reach/router';
import sanitizeOptions from "../../helper/sanitizeOptions";

function VacancyPart({data}) {
    const sanitizeOpt = sanitizeOptions();
    const {vacancy, careers} = data;
    const {title, content, vacancyCustomFields, seo, additionalMetaTag} = vacancy;
    const {careerCustomFields} = careers;
    const {careersContactUsBlock, careerBreadcrumbs} = careerCustomFields;
    const dataContactBlock = careersContactUsBlock;
    const {careersLocation, vacancyOptions, button} = vacancyCustomFields;
    const vacancyBlockId = 'sent-cv-block';
    // Set breadcrumbs
    const {location} = history;
    const getCrumbs = Array.isArray(careerBreadcrumbs) && careerBreadcrumbs;
    const LastCrumb = {
        crumblabel: title,
        pathname: location.pathname
    };
    const crumbs = [...getCrumbs, LastCrumb];
    // Set metadata
    const seoData = {...{seo}, additionalMetaTag, title};

    // property={'orange-background'} it's for change the background color for pre-footer
    // property={'dark-background'} by default

    return (
        <Layout
            crumbs={crumbs}
            setClass={'vacancy'}
            seoData={seoData}>
            <div className={'vacancy inner-pages'}
                 datafld={{dataContactBlock}}
                 property={'orange-background'}>
                <Helmet
                    bodyAttributes={{
                        class: 'vacancy-page-wrapper'
                    }}
                />
                <div className={'h5-title'}>
                    {careersLocation}
                </div>
                <div className={'vacancy__title title-large'}>
                    <h1 className={'title'}>
                        <span data-delay="500"
                              className="spring-animation">
                        {title}
                        </span>
                    </h1>
                    {!!button && (
                        <div className={'vacancy__button'}>
                            <MageButton
                                clickEvent={() => scrollTo(`#${vacancyBlockId}`)}
                                color={`#EC5C2B`}
                                size={145}
                                text={button}
                                textColor={`white`}
                                breakWords={true}
                            />
                        </div>
                    )}
                    {!!content && (
                        <div className={'vacancy__description'}
                             dangerouslySetInnerHTML={{__html: sanitizeHtml(content, sanitizeOpt.contentData)}}/>
                    )}
                </div>
                {!!vacancyOptions && (
                    <ul className={'vacancy__options-block'}>
                        <ContentListItems
                            data={vacancyOptions}
                            useCounter={false}
                        />
                    </ul>
                )}
                {!!vacancyBlockId && (
                    <div id={vacancyBlockId}
                         className={'vacancy__sent-cv-block'}/>
                )}
            </div>
        </Layout>
    )
}

export default VacancyPart
