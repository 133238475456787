import React from "react"
import {graphql} from "gatsby"
import VacancyPart from "../../components/template-parts/vacancy-part";

export default ({data}) => <VacancyPart data={data}/>

export const query = graphql`
    query vacancy($id: String!) {
        vacancy: wpVacancy(id: { eq: $id }) {
            title
            content
            vacancyCustomFields {
                careersLocation
                button
                vacancyOptions{
                    fieldGroupName
                    title
                    text
                }
            }
            seo {
                title
                metaDesc
                canonical
            }
            additionalMetaTag {
                keywords
                ogDescription
                ogTitle
                ogType
                ogImage {
                    mediaItemUrl
                }
                twitterImage{
                    mediaItemUrl
                }
                twitterTitle
                twitterDescription
            }
        }
        careers: wpPage(slug: {eq: "careers"}) {
            careerCustomFields {
                careersContactUsBlock {
                    description
                    text
                    titleH2
                    titleH4
                    classWrapper
                }
                careerBreadcrumbs {
                    crumblabel
                    pathname
                }
            }
        }
    }
`
